'use client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { enUS, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Inter, Poppins } from 'next/font/google';
import { ReactNode } from 'react';

import { lightTheme } from '@/styles/theme';

const inter = Inter({
  weight: ['400', '500'],
  subsets: ['latin'],
  variable: '--font-inter',
});

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
  variable: '--font-poppins',
});

export default function InnerApplication({
  children,
}: Readonly<{
  children: ReactNode;
}>) {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <body
        className={`${inter.variable} ${poppins.variable}`}
      >
        <LocalizationProvider
          localeText={
            enUS.components.MuiLocalizationProvider.defaultProps.localeText
          }
          dateAdapter={AdapterDayjs}
        >
          {children}
        </LocalizationProvider>
      </body>
    </ThemeProvider>
  );
}
