import { FC } from 'react';

import { IconsProps } from '@/components/icons/types';

const StepCheck: FC<IconsProps> = ({ className }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    opacity="0.15"
    xmlns="http://www.w3.org/2000/svg"
    color="#4728CD"
    className={className}
    data-testid="step-check"
  >
    <path
      d="M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z"
      fill="currentColor"
      fillOpacity="currentOpacity"
    />
    <path
      d="M16.25 10.8335L11.9167 15.1668L9.75 13.0002"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default StepCheck;
